import { Divider, Link, List, ListItem, Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'

const formsLink = {
    "Membership Forms": [
        { "desc": "Membership Form", "link": "" },
        { "desc": "Donation Form", "link": "" }
    ]
}

function Forms() {
    return (
        Object.keys(formsLink).map((i) => {
            return (
                <div>
                    <Text fontSize='3xl'>{i}</Text>
                    <List spacing={2}>
                        {formsLink[i].map((j) => {
                            return (
                                <ListItem><Link fontSize={16} color={"teal"} href={j["link"]} isExternal>
                                    {j["desc"]} <ExternalLinkIcon mx='2px' />
                                </Link></ListItem>
                            )
                        })}
                    </List>
                    <Divider />
                </div>
            )
        })
    );
}

export default Forms;
