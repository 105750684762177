import React from 'react';
import { Box, Text, Image, VStack } from '@chakra-ui/react';

const FolderGrid = ({ folder, onOpen }) => {
  return (
    <VStack
      align="start"
      spacing={4}
      mb={6}
      cursor="pointer"
    >
      <Box
        position="relative"
        w="450px"
        h="400px"
        overflow="hidden"
        onClick={onOpen}
        _hover={{ transform: 'scale(1.01)', transition: '0.1s' }}
      >
        {folder.images.slice(0, 3).map((image, index) => (
          <Image
            key={image.file_id}
            src={image.local_path}
            alt={image.file_name}
            position="absolute"
            top={index * 4 + 'px'}
            left={index * 4 + 'px'}
            borderRadius="md"
            boxShadow="md"
            transform={`rotate(${index * 5 - 5}deg)`}
          />
        ))}
        <Text fontSize="md" fontWeight="bold" position={"absolute"} bottom={0}>
          {folder.description ? folder.description : folder.folder_name}
        </Text>
      </Box>
    </VStack>
  );
};

export default FolderGrid;