import React from 'react';
import {
  Box,
  Image,
  Text,
  SimpleGrid,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

function ImageList(props) {
  const data = props.data;
  const {photoId} = useParams();
  var cur_folder = []
  if (data !== null) {
    for (var folder of data["subfolders"]) {
      if (folder["folder_id"] === photoId) {
        cur_folder = folder
      }
    }
  }
  
  return (
    data ? <Box padding={4}>
      <Text alignSelf="start" fontSize={32}>{cur_folder.description}</Text>
      <br></br>
      <SimpleGrid columns={[1, 2, 3]} spacing={4}>
        {cur_folder["images"].map((image, index) => (
          <VStack key={index} spacing={2} align="center">
            <Image src={image.local_path} alt={image.file_name} borderRadius="lg" />
            <Text alignSelf="start">{image.description}</Text>
          </VStack>
        ))}
      </SimpleGrid>
    </Box> : <Spinner />
  );
};

export default ImageList;