import styles from "./css/Header.module.css"
function Header() {
    return (
        <div className={styles.header}>
            <div>
                <img src={"/logo.png"} alt={"logo"} />
            </div>
            <div className={styles.text_container}>
                <div className={styles.text_h1}>BHARATHIYA ELECTRICITY ENGINEERS ASSOCIATION</div>
                <div className={styles.text_h3}>A Unit of Bharathiya Mazdoor Sangh</div>
                <div className={styles.text_h6}>(Reg. No. : 202/2016)</div>
            </div>
            <div>
                <img className={styles.image} src={"/matha.png"} alt={"logo"} />
            </div>
        </div>
    )
}
export default Header;