import { Box, Heading } from '@chakra-ui/react';
import FolderGrid from './FolderGrid.js';
import { Link } from 'react-router-dom';

function Photos(props) {
  const data = props.data

  return (
    <Box p={6}>
      <Heading as="h1" mb={6} textAlign="center">Photo Gallery</Heading>
      {data && data.subfolders && (
        <Box>
          {data.subfolders.map((folder) => (
            <Link to={"/photos/" + folder.folder_id}>
              <FolderGrid
                key={folder.folder_id}
                folder={folder}
              />
            </Link>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default Photos;
