import Feeds from "./Feeds";
import ImageSlide from "./ImageSlide";

const image_list = [
        "/images/bms_1.jpg",
        "/images/bms_2.jpg"
];

function Home() {
    return (
      <div>
        <ImageSlide images={image_list} />
        <Feeds />
      </div>
    );
  }
  
  export default Home;
  