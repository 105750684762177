import React, { useState, useEffect } from 'react';
import { Box, Image, Flex, IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const ImageSlide = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);  

  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);  

  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNextImage();
    }, 5000); // Adjust the interval as needed

    return () => clearInterval(intervalId);
  }, [images]);

  return (
    <Box maxW="full" h="400px">
      <Flex justifyContent="space-between" alignItems={"center"} p={4}>
        <IconButton
          aria-label="Previous Slide"
          onClick={handlePrevImage}
          icon={<ChevronLeftIcon />}
        />
        <Image src={images[currentImageIndex]} alt="Slide" objectFit="contain" w="full" h="300" />
        <IconButton
          aria-label="Next Slide"
          onClick={handleNextImage}
          icon={<ChevronRightIcon />}
        />
      </Flex>
    </Box>
  );
};

export default ImageSlide;