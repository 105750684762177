import { Box, Card, CardBody, CardHeader, Heading, HStack, Link, Stack, StackDivider, Tag, Text } from "@chakra-ui/react";
import Feeds_Data from "./data/Feeds_Data.json"
function groupListByDate(items) {
    const groupedItems = {};

    items.forEach(item => {
        const date = new Date(item.time * 1000).toDateString(); // Convert UNIX timestamp to date string

        if (!groupedItems[date]) {
            groupedItems[date] = [];
        }

        groupedItems[date].push(item);
    });
    Object.values(groupedItems).forEach(group => group.sort((a, b) => b.time - a.time));
    window.a = groupedItems;
    return groupedItems;
}
var groupedData = groupListByDate(Feeds_Data)
var sortedKeys = Object.keys(groupedData).sort((i) => new Date(i))
function Feeds() {
    return (
        <Stack>
            {sortedKeys.map((k) => {
                return (
                    <Card>
                        <CardHeader>
                            <Heading size='md'>{k}</Heading>
                        </CardHeader>

                        <CardBody>
                            <Stack divider={<StackDivider />} spacing='4'>
                                {groupedData[k].map((i) => {
                                    return (
                                        <Box>
                                            <Heading size='xs' textTransform='uppercase'>
                                                {i["title"]}
                                            </Heading>
                                            <Text pt='2' pb='4' fontSize='md'>
                                                {i["description"]}
                                            </Text>
                                            <Link color='teal' href={i["link"]} isExternal>{decodeURI(new URL(i["link"]).hash)}</Link>
                                            <HStack spacing={2} pt={2}>
                                                {i["tags"].map(tag => <Tag>{tag}</Tag>)}
                                            </HStack>
                                        </Box>
                                    )
                                })}

                            </Stack>
                        </CardBody>
                    </Card>
                )

            })}

        </Stack>
    );
}

export default Feeds;
